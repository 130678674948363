/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://pixabay.com/photos/meeting-brainstorming-business-594091/"
  }, "썸네일 원본")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "운영 회의: 2주마다 한 번씩 녹화하기"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "오랜만의 대면 AWS Summit"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "개발자 커뮤니티에는 왜 가나요?"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "애플 에픽게임즈 재판 결과"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "세가, 로비오 1조 인수"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "깃헙 스튜던트 팩: 1password가 포함됨!"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Homebrew를 만든 맥스 호웰이 새로 시작한 tea 프로젝트"), "\n"), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
